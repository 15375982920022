<template>
  <div class="flex-grow-1">
    <UserTitle>
      <template v-slot:actions>
          <div>
            <h2 class="text-h3 font-weight-black">My Invites</h2>
          </div>
      </template>
    </UserTitle>

    <!-- Filters -->
    <div class="mb-15">
      <v-row>
        <v-col cols="12" class="jobs-section"> 
          <preloader v-if="status.getting" />
          <div class="text-center" v-if="!jobs.length">
              No records found.
          </div>
          <div>
            <!-- Job Card -->
            <div v-if="ordered">
              <JobsCard 
              v-for="job in ordered"
              :key="job.id"
              :job="job"
              />
            </div>
              <!-- <div v-if="user && user.role == 'jobseeker' && !status.getting">
                <v-card class="mb-6 shadow-lg" v-for="(job, index) in ordered" :key="index">
                  <v-card-text class="pl-9 pr-9">
                  <v-row>
                      <v-col cols="3 d-flex justify-center">
                      <div>
                          <v-card outlined>
                          <v-card-text>
                              <v-img
                              :src="job.logoURL"
                              min-height="160"
                              width="160"
                              contain
                              ></v-img>
                          </v-card-text>  
                          </v-card>
                      </div>
                      </v-col>
                      <v-col cols="9">
                      <v-row>
                          <v-col cols="12">
                          <div class="d-flex justify-space-between align-center mb-3">
                              <span class="text-h5 font-weight-bold text-capitalize">
                              <router-link :to="{ path: '/job/' + job.id }"
                                  class="font-weight-bold dark--text text--darken-2 font-weight-bold text-capitalize text-decoration-none"
                              >
                                  {{ job.title }}
                              </router-link>  
                              </span>

                              <span 
                              class="text-p"
                              v-if="job.remote"
                              >
                              <v-icon left size="20">mdi-laptop</v-icon> Remote Work
                              </span>
                          </div>
                          <div>
                              <v-row no-gutters>
                              <v-col cols="6">
                                  <div class="d-flex gap-5 flex-wrap">
                                  <v-btn
                                      v-for="(skill, skillIndex) in job.skillSet" 
                                      :key="skillIndex"
                                      class="font-weight-regular text-capitalize"
                                      depressed
                                      rounded
                                      small
                                  >
                                      {{ skill }}
                                  </v-btn>
                                  </div>
                              </v-col>
                              <v-col cols="6">
                                  <div class="text-right">
                                  <span class="dark--text font-weight-bold text-capitalize">
                                      <v-icon left size="32">mdi-map-marker-outline</v-icon>
                                      <span>{{ job.location }}</span>
                                  </span>
                                  </div>
                              </v-col>
                              </v-row>
                          </div>
                          <v-divider class="my-5"></v-divider>
                          <p class="job-description body-1 ls-0">
                              {{ job.overview }}
                          </p>
                          <div class="d-flex justify-space-between">
                            <div class="mt-5">
                                <router-link :to="{ path: '/job/' + job.id }"
                                class="font-weight-medium black--text text-capitalize text-decoration-underline"
                                >
                                View More
                                </router-link>
                            </div>
                          </div>
                          </v-col>
                      </v-row>
                      </v-col>
                  </v-row>
                  </v-card-text>
              </v-card>
            </div> -->
            <!-- End Job Card -->
          </div>
        </v-col>

      </v-row>
    </div>
    <!-- End Filters -->

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import JobsCard from '@/views/job/JobCard'
import _orderBy from 'lodash/orderBy'

export default {
  name: 'Invites',

  metaInfo: {
    title: 'Invites'
  },

    data() {
    return {
      orderBy: 'desc'
    }
  },

  components: {
    JobsCard,
  },

  computed: {
    ...mapState({
      user: state => state.user.user,
      jobs: state => state.invite.myInvites,
      status: state => state.invite.status,
    }),

    ordered: function () {
      return _orderBy(this.jobs, 'createdAt', this.orderBy)
    }

  },

  methods: {
    ...mapActions('invite', [
        'getInvites',
    ]),

    filterOrder(sortData) {
      this.orderBy = sortData.order
    },
  },
  
  created() {
    this.getInvites()
  }
}
</script>